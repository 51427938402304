import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"



const SuccessPage = () => (
  <Layout>
    <Seo title="Success" />
 
    <div className="bg-white text-white relative lg:py-20">
      <div className="container text-center mx-auto py-10 lg:px-10 max-w-4xl">
        <h1 className="text-3xl lg:text-5xl p-10 font-bold text-black">
         Thank you! We received your request, we will get back to you very soon.
        </h1>
      </div>
    </div>
    
  </Layout>
)

export default SuccessPage
